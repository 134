document.addEventListener("DOMContentLoaded", () => {
  const navSearch = document.querySelector("#desktop-header-search");

  if (navSearch) {
    const closeIcon = document.querySelector("[data-search-close]");
    const searchIcon = document.querySelector("[data-search-open]");

    searchIcon.addEventListener("click", () => {
      toggleSearchIcons(true);
      toggleSearchField(true);
    });

    closeIcon.addEventListener("click", () => {
      toggleSearchField(false);
      toggleSearchIcons(false);
    });

    function toggleSearchIcons(showSearchIcon) {
      searchIcon.style.display = showSearchIcon ? "none" : "block";
      closeIcon.style.display = showSearchIcon ? "block" : "none";
    }

    function toggleSearchField(showSearchField) {
      navSearch.style.display = showSearchField ? "block" : "none";
      if (showSearchField) {
        navSearch.focus();
      }
    }
  }
});
