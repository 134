var placeSearch, autocomplete;

function initAutocomplete(scope) {
  // Create the autocomplete object, restricting the search predictions to
  // geographical location types.
  autocomplete = new google.maps.places.Autocomplete(scope.querySelector(".autocomplete"), {
    types: ["street_number", "route", "street_address", "locality", "administrative_area_level_3"],
    componentRestrictions: { country: "au" },
  });
  // Avoid paying for data that you don't need by restricting the set of
  // place fields that are returned to just the address components.
  autocomplete.setFields(["address_component"]);

  // When the user selects an address from the drop-down, populate the
  // address fields in the form.
  autocomplete.addListener("place_changed", function (e) {
    fillInAddress(scope);
  });
}

function searchLocationsNear(center, scope) {
  var xmlhttp = new XMLHttpRequest();
  xmlhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      $locationArr = JSON.parse(this.responseText);

      // make changes to the default loaded health engine stuff
      var iframeContainer = document.querySelector("#he-webplugin-popup-1234");
      var targetIframe = iframeContainer.querySelector("iframe");
      // change iframe src and name
      targetIframe.src =
        "https://healthengine.com.au/webplugin/?id=" +
        $locationArr["healthEngineID"] +
        "&source=webplugin&_ga=2.237139072.1471497193.1587336925-1048464626.1587336925&trigger=button";

      showResult('Your closest Tooth Fairy practice is <a href="' + $locationArr["link"] + '">' + $locationArr["location"] + "</a>", scope);

      // show the buttons
      showButtons(scope);
    }
  };

  xmlhttp.open("GET", "/wp-content/themes/smile-theme-child/inc/search-locations.php?lat=" + center.lat() + "&lng=" + center.lng(), true);
  xmlhttp.send();
}

function showButtons(scope) {
  var refButtonContainer = scope.querySelector(".location-buttons");
  refButtonContainer.querySelector(".contact").href = $locationArr["link"];
  $(window).width() < 767 ? (refButtonContainer.style.display = "block") : (refButtonContainer.style.display = "flex");
}

function hideButtons() {
  // hide all the location buttons
  var refButtonContainers = document.getElementsByClassName("location-buttons");
  for (var i = 0; i < refButtonContainers.length; i++) {
    refButtonContainers[i].style.display = "none";
  }
}

function resizeIframeContainer() {
  var iframeContainer = document.querySelector("#he-webplugin-popup-1234");
  iframeContainer.style.height == "99%" ? (iframeContainer.style.height = "100%") : (iframeContainer.style.height = "99%");
}

function fillInAddress(scope) {
  // Get the place details from the autocomplete object.
  var address = scope.querySelector(".autocomplete").value;

  // at this point if VIC doesn't exists in the address return an error to the screen
  if (address.search("VIC") === -1) {
    hideButtons();
    showResult("Your address is outside Victoria", scope);
    return;
  }

  var geocoder = new google.maps.Geocoder();
  geocoder.geocode({ address: address }, function (results, status) {
    if (status == google.maps.GeocoderStatus.OK) {
      searchLocationsNear(results[0].geometry.location, scope);
    } else {
      hideButtons();
      showResult("Your address is not found", scope);
    }
  });
}

// Bias the autocomplete object to the user's geographical location,
// as supplied by the browser's 'navigator.geolocation' object.
function geolocate() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      var geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      var circle = new google.maps.Circle({ center: geolocation, radius: position.coords.accuracy });
      autocomplete.setBounds(circle.getBounds());
    });
  }
}

function showResult(value, scope) {
  scope.querySelector(".location-closest").innerHTML = value;
}

jQuery(document).ready(function ($) {
  $(".search-switch").click(function (e) {
    initAutocomplete($(this).parent()[0]);
    // take the border-radius off the switch and add to the ui
    // if the search is open
    if ($(this).not(".open")) {
      $(this).addClass("open");
      $(this).removeClass("has-corners");
    }

    var searchUI = $(this).next(".search-ui");
    var uiHidden = searchUI.is(":hidden");

    searchUI.slideToggle({
      duration: 200,
      easing: "linear",
      start: function (a) {
        if (uiHidden == false) {
          $(this).prev(".search-switch").removeClass("open");
        }
      },
      done: function (a) {
        $(this).prev(".search-switch").addClass("has-corners");
      },
    });
  });
});
