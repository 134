jQuery(document).ready(function ($) {
  $(".galleryModal").click(function (event) {
    console.log("clicked ", $(this).data("image"));
    var button = $(event.currentTarget); // Button/link that triggered the modal
    var image = $(this).data("image"); // Get the image URL from the data-image
    // attribute of the button/link above.
    var modal = $(this);

    // Update the modal's content.
    $(".modal-body").html('<img src="' + image + '" alt="" />');
  });
});
