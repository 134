jQuery(document).ready(function ($) {
  $(".location-card__heading").click(function (e) {
    if ($(window).width() < 767) {
      if ($(this).parent().hasClass("open")) {
        $(this).parent().removeClass("open");
        $(this).next().slideUp();
      } else {
        $(this).parent().addClass("open");
        $(this).next().slideDown();
      }

      // all others will be closed regardless
      $(".location-card__content").not($(this).next()).slideUp().parent().removeClass("open");
    }
  });
  $(window).resize(function () {
    if ($(this).width() > 767) {
      $(".location-card__content").slideDown().parent().removeClass("open");
      $(".location-card__content").removeAttr("style");
    }
  });
});
